import React from "react";
import "@typeform/embed/build/css/widget.css";
import { useEffect, useRef } from "react";
import { createWidget } from "@typeform/embed";
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: ${props => props.height || "700px"};
  margin: 0 auto;
`;

function TypeFormEmbedForm(props) {
  const container = useRef();

  useEffect(() => {
    createWidget(props.id, { container: container.current });
  }, [props.id]);

  return (
    <Container height={props.height} className="App-embed" ref={container} />
  );
}

export default TypeFormEmbedForm;