import React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import { MDXRenderer } from "gatsby-mdx";
import { MDXProvider } from "@mdx-js/react"
import { Colors } from '../utils/constants';
import styled from 'styled-components';
import globalComponents from '../components/BlogGlobalComponents';
import { centerize, centerizeBody } from '../utils/commonCSS';

function Blog(props) {
  const edges = props.data.allMdx.edges;
  let sideNode;
  let bodyNode;
  let footerNode;

  edges.forEach(({node}) => {
    const id = node.frontmatter.id;
    if (id.indexOf('side') !== -1) {
      sideNode = node;
    } else if (id.indexOf('footer') !== -1) {
      footerNode = node;
    } else {
      bodyNode = node;
    }
  });

  const title = bodyNode.frontmatter.title;
  const body = bodyNode.code.body;
  const seoDesc = bodyNode.frontmatter.seoDescription;
  const renderSide = () => (
    sideNode && (
      <div className="side">
        <div className="side__main">
          <MDXRenderer>{sideNode.code.body}</MDXRenderer>
        </div>
      </div>
    )
  );

  const renderFooter = () => (
    footerNode && (
      <div className="footer">
        <MDXRenderer>{footerNode.code.body}</MDXRenderer>
      </div>
    )
  );

  return (
    <MDXProvider components={globalComponents}>
      <PageLayout title={title} seoDesc={seoDesc}>
        <div className={props.className}>
          <div className={sideNode ? 'with-side': 'without-side'}>
            <div className="body">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
            {renderSide()}
            {renderFooter()}
          </div>
        </div>
      </PageLayout>
    </MDXProvider>
  )
}

export default styled(Blog)`
  .with-side {
    display: grid;
    grid-template-columns: 66.6% auto;
    grid-column-gap: 32px;
  }
  width: 100%;
  .without-side {
    display: flex;
    flex-direction: column;
    ${centerize}
    .body {
      ${centerizeBody}
    }

  }


  a {
    color: ${Colors.EYA_RED};
    text-decoration: underline;
  }

  .side {
    .side__main {
      position: sticky;
      top: 16px;
    }
  }

  .footer {
    grid-column: 1 / 3;
  }

  @media screen and (max-width: 768px) {
    .with-side {
      display: flex;
      flex-direction: column;
    }
    .side {
      order: -1;
      ul {
        margin: 0;
      }
    }
  }
`;


export const query = graphql`
  query($id: String) {
    allMdx(
        filter: {frontmatter: {id: { regex: $id}}}
    ) {
      edges {
        node {
          frontmatter {
            id
            title
            seoDescription
          }
          code {
            body
          }
        }
      }
    }
  }
`;