import React from "react";
import EmbedScript from '../EmbedScript';

const KwikSurveyEmbedForm = (props) => {
  return (<EmbedScript
    src="https://kwiksurveys.com/ShoutEmbed/embed.min.js"
    id="shoutscript"
  >
    <div shout-embed="true" sh-form={props.formId}></div>
  </EmbedScript>);
};

export default KwikSurveyEmbedForm;