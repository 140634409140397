import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({ src }) => {
  return (
    <VideoWrapper>
      <iframe
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen=""
        title={src}
      />
    </VideoWrapper>
  );
}

export default Video;