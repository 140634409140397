import React from 'react';
import AddToCalendarReact from 'react-add-to-calendar';
import styled from 'styled-components';
import { Colors } from '../utils/constants';


const AddToCalendar = (props) => {
  return (
    <AddToCalendarReact event={props.event} rootClass={props.className} displayItemIcons={true} />
  )
}

export default styled(AddToCalendar)`
color: blue !important;
position: relative;

.react-add-to-calendar__button {
  cursor: pointer;
}

.react-add-to-calendar__dropdown {
  width: fit-content;
  border: 1px solid hsla(0,0%,0%,0.8);
  position: absolute;
  z-index: 1;
  background-color: white;
}
ul {
  margin: 0;
  padding: 0;
}
li a {
 margin-left: 0;
 padding: 8px 16px;
 width: 100%;
 display: block;
}

li:hover {
  a {
    background-color: ${Colors.EYA_RED};
    color: white;
  };
}
`