import React, { useEffect, useRef } from "react";

const EmbedScript = (props) => {
  const containerRef = useRef();
  useEffect(() => {
    const script = document.createElement('script');
    const container = containerRef.current;
    script.src = props.src;
    script.id = props.id;
    script.type = "text/javascript";
    script.async = true;
    container.appendChild(script);
  
    return () => {
      container.removeChild(script);
    }
  }, [props.id, props.src]);
  return (
    <div ref={containerRef}>
      {props.children}
    </div>
  )
}

export default EmbedScript;
