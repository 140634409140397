import Blockquote from './Blockquote';
import Slider from './Slider';
import Video from './Video';
import ArtistsList from './ArtistsList';
import KwikSurveyEmbedForm from './ThirdPartyEmbedForms/KwikSurveyEmbedForm';
import TypeFormEmbedForm from './ThirdPartyEmbedForms/TypeFormEmbedForm';
import HoverableLink from './HoverableLink';
import Button from './Button';
import AddToCalendar from './AddToCalendar'
import * as Gifs from './Gifs'

export default {
  Blockquote,
  Slider,
  Video,
  ArtistsList,
  KwikSurveyEmbedForm,
  TypeFormEmbedForm,
  HoverableLink,
  Button,
  AddToCalendar,
  ...Gifs
};